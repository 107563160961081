import sbuHandbook from './SBU_Handbook_03.2024.pdf'
import {Link} from "react-router-dom";
import React from "react";
function BuddhistChaplaincy() {
    return (
        <>
            <div className="my-sm-3 text-justify">
                <h5 className="mb-4">Buddhist Chaplaincy</h5>
                <p>Are you considering advancing into the role of a Buddhist Chaplain as the next progression in your
                    Buddhist journey? Do you aspire to align your career with Buddhist values? Are you motivated to
                    bring solace and fortitude to both yourself and others through your work? Do you wish to extend your
                    support to individuals grappling with the challenges of aging, illness, death, and incarceration?
                    Are you drawn to the narratives of others and find fulfillment in assisting them on their spiritual
                    journeys? Does the prospect of both academic and spiritual growth intrigue you?</p>

                <p>If you are seeking to embark on the path to becoming a chaplain, the Sakya Buddha University's
                    Buddhist Chaplaincy program might be precisely what you are looking for. This program is dedicated
                    to preparing Buddhist practitioners for impactful roles in chaplaincy across diverse settings,
                    including hospitals, hospices, healthcare facilities, universities, schools, the private sector,
                    prisons, police departments, and rehabilitation facilities, as well as within the US Armed Services,
                    all within interfaith contexts. Buddhist Chaplains trained through this program play vital roles in
                    various sectors, contributing to the well-being of individuals and communities.</p>

                <p>Under the guidance of our faculty, students actively participate in learning and practical training
                    in Buddhist chaplaincy. The department collaborates closely with the Philosophy, Psychology, and
                    Religious Studies departments, emphasizing the real-life applications of Buddhist Chaplaincy
                    knowledge.</p>

                <p>Our ecumenical department welcomes students from all branches of Buddhism and various religious
                    denominations. While not all students exclusively identify as Buddhist, they are encouraged to draw
                    from their faith traditions for spiritual reflection and formation, sharing these insights with
                    their peers. Additionally, students understand other faiths, preparing them to serve a diverse range
                    of individuals in need.</p>
                <ul></ul>
                <div>
                    <Link to={`/academics-program/3`} className="btn btn-primary mb-3  text-left"
                          style={{width: '45%'}}>
                        Master of Arts in Buddhist Spiritual
                    </Link>
                </div>
                <div>
                    <Link to={`/academics-program/8`} className="btn btn-primary mb-3  text-left"
                          style={{width: '45%'}}>
                        Master of Divinity
                    </Link>
                </div>
                <div>
                    <Link to={`/academics-program/5`} className="btn btn-primary mb-3  text-left"
                          style={{width: '45%'}}>
                        Doctor of Buddhist Chaplaincy
                    </Link>
                </div>

            </div>
        </>
    );
}

export default BuddhistChaplaincy;