import React, {createContext, useState} from 'react';
import AcademicsIntro from "./AcademicsIntro";
import CourseCatalog from "./CourseCatelog";
import Undergraduate from "./Undergraduate";
import Certificate from "./Certificate";
import AcademicCalendar from "./AcademicCalendar";
import Faculty from "./Faculty";
import Libraries from "./Libraries";
import Graduate from "./Graduate";
import Handbook from "./Handbook"
import {useHashChange} from "../hooks/useHashChange";
import sbuHandbook from './SBU_Handbook_03.2024.pdf'

export const LinksContext = createContext(undefined);

export const LinksProvider = ({ children }) => {
    const links = [
        { name: null, component: AcademicsIntro },
        { name: '2023 - 2025 Course Catalog', component: CourseCatalog, id: 'CourseCatalog' }, // Fixed typo
        { name: 'SBU HandBook', component: Handbook, id: 'Handbook' }, // Fixed typo
        { name: 'Graduate Academics', component: Graduate, id: 'Graduate' }, // Ensure id is unique if needed
        // { name: 'Undergraduate Academics', component: Undergraduate, id: 'Undergraduate' }, // Ensure id is unique if needed
        // { name: 'Certificate Academics', component: Certificate, id: 'Certificate' }, // Ensure id is unique if needed
        { name: 'Academic Calendar', component: AcademicCalendar, id: 'AcademicCalendar' },
        { name: 'Faculty', component: Faculty, id: 'Faculty' }, // Ensure id is unique if needed
        { name: 'Libraries', component: Libraries },
    ];
    const [activeLink, setActiveLink] =  useHashChange(links);

    return (
        <LinksContext.Provider value={{ links, activeLink, setActiveLink }}>
            {children}
        </LinksContext.Provider>
    );
};
