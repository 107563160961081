import sbuHandbook from './SBU_Handbook_03.2024.pdf'
import {Link} from "react-router-dom";
import React from "react";

function BuddhistPhilosophy() {
    return (
        <>
            <div className="my-sm-3 text-justify">
                <h5 className="mb-4">Master of Arts in Buddhist Philosophy</h5>
                <p>The Master of Arts two-year program provides comprehensive coursework in one of the
                    principal Buddhist canonical languages as well as detailed analyses of the impact of Buddhist
                    philosophy on culture, belief, and history throughout centuries.</p>

                <p>The first year emphasizes intensive training in the chosen language relevant to theological
                    studies of Buddhist history, literature, and philosophy. The second year is an in-depth study of
                    principal Buddhist scriptures and doctrines in the chosen language.</p>

                <p>This degree program aims to deepen students’ academic Buddhist philosophical pursuits and
                    serve as a standalone degree or as a transition preparation for the Shakya Buddha University
                    doctorate program. Admission Requirements: A Bachelor of Arts degree (preferably in
                    philosophy, history, psychology, theology, or sociology). Two letters of recommendation, from
                    either academic or professional references. A Test of English as a Foreign Language (TOEFL) or
                    International English Language Testing System (IELTS) is required for non-native English
                    speakers.</p>

                <p>Degree Requirements: The M.A. program includes two years of graduate-level coursework
                    followed by the completion of a thesis within the chosen subfield and area of specialization. The
                    overall time needed to complete the degree may vary, but students typically finish in a minimum
                    of two years.</p>
                <p>Below are the basic requirements of the degree. Further details, policies, and additional
                    requirements can be referenced in the <a href={sbuHandbook} target="_blank">Sakya Buddha University Handbook</a></p>
                <ul>
                    <li>Two years of full-time study.</li>
                    <li>Sixteen courses (48 units), including nine lecture classes, three elective classes, and four
                        research methodology classes.
                    </li>
                    <li>The courses must be taken for a letter grade, and students must receive grades of B- or
                        higher.
                    </li>
                    <li>Residency requirement: completion of four courses in each of the first three consecutive
                        terms.
                    </li>
                    <li>Thirteen of the 16 mandatory classes must be taken for a letter grade, while the remaining
                        three may be taken on a satisfactory/unsatisfactory basis.
                    </li>
                    <li>Maintaining a B average is required throughout the program.</li>
                    <li>Completion of a thesis within the chosen subfield and area of specialization is
                        mandatory.</li>
                    <li>Duration of the program: minimum of two years, full time.</li>
                </ul>


            </div>
        </>
    );
}

export default BuddhistPhilosophy;