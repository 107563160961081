import sbuHandbook from './SBU_Handbook_03.2024.pdf'
import {Link} from "react-router-dom";
import React from "react";

function Divinity() {
    return (
        <>
            <div className="my-sm-3 text-justify">
                <h5 className="mb-4">Master of Divinity</h5>
                <p>The Master of Divinity (MDIV) degree, a comprehensive 72-unit graduate professional program, is
                    meticulously designed to prepare students for active and confident involvement in ministry,
                    chaplaincy, related professions, and further academic pursuits. Within a Buddhist seminary, this
                    program offers a distinctive opportunity for students to develop a nuanced understanding of Buddhist
                    traditions. It also promotes a well-informed appreciation of other faith traditions and the dynamic
                    religious and spiritual landscape of contemporary society.</p>

                <p>At the core of the MDIV program lies a steadfast commitment to diversity and dialogue — fundamental
                    elements aligned with its overarching objective: the cultivation of skilled, thoughtful, and
                    effective leaders firmly rooted in the Buddhist tradition. These leaders are not only capable of
                    critical thinking but are also equipped to respond constructively to the ever-evolving needs of our
                    changing world.
                    The SBU MDIV program extends a warm welcome to students from all Buddhist traditions. For those
                    pursuing ministerial ordination in a specific tradition, consultation with their community is
                    recommended to ensure that coursework aligns with ordination requirements. Specialized tracks of
                    study are available for students interested in professional chaplaincy, providing additional
                    structure to the MDIV program. Additionally, students may explore concurrent certificate programs to
                    guide and focus their studies.</p>

                <p><strong>Students enrolled in SBU's Master of Divinity program will:</strong></p>
                <ul>
                    <li>Demonstrate a comprehensive grasp of Buddhist history, literature, thought, practice, and
                        ethics, establishing a solid foundation for effective ministry, chaplaincy, or religious
                        leadership
                    </li>
                    <li>Illustrate how these skills are applicable to their ministry, acquiring specific and in-depth
                        knowledge of the teachings and practices within their Buddhist tradition. They will articulate a
                        well-defined understanding of how this knowledge serves as a guide and resource for their
                        ministry.
                    </li>
                    <li>Establish connections between their own Buddhist tradition and diverse Buddhist contexts,
                        fostering effective engagement with multi-religious and multi-cultural communities.
                    </li>
                    <li>Identify and express ministerial skills pertinent to their professional goals, drawing from a
                        diverse range of sources, both Buddhist and non-Buddhist.
                    </li>
                    <li>Demonstrate proficiency in research projects and communication skills, including writing, public
                        speaking, and delivering dharma talks.
                    </li>
                </ul>
                <p>To ensure a comprehensive foundation for Buddhist ministry, chaplaincy, and religious leadership in
                    an increasingly interfaith and intercultural world, all students are required to complete courses
                    across five subject areas.</p>


            </div>
        </>
    );
}

export default Divinity;